
// @see: https://vue-final-modal.org/
import { VueFinalModal } from 'vue-final-modal';

const ModalCloseType = [null, 'peach', 'darkPurpleMedium'];

export default {
  name: 'deputy-modal-template',
  components: {
    VueFinalModal,
    iconCloseModal: () => import('assets/svg/icons/icon-close-modal.svg'),
  },
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      validator: type => ModalCloseType.includes(type),
      default: null,
    },
  },
  computed: {
    modalCloseClass() {
      let classes = 'modal__close';
      if (this.color !== null) {
        classes = `modal__close${'-' + this.color}`;
      }
      return classes;
    },
    modalContentClass() {
      let classes = 'modal__content';
      if (this.color !== null) {
        classes = `modal__content${'-' + this.color}`;
      }
      return classes;
    },
  },
  methods: {
    closed() {
      this.$store.commit('analytics/setActionSource', null);
    },
  },
};
